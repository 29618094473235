/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        /////////////
        // Typekit //
        /////////////

        try{Typekit.load({ async: true });}catch(e){}



        ////////////////
        // Mobile Nav //
        ////////////////

        $('[name="MainContent"]').next().attr('id', 'MainContent');

        var mobileNav = $('.mobile-nav');
        mobileNav.find('a').each(function() {
          $(this).attr('tabindex', '-1');
        });
        mobileNav.find(':input').each(function() {
          $(this).attr('tabindex', '-1');
        });
        mobileNav.find('button').each(function() {
          $(this).attr('tabindex', '-1');
        });
        $('.mobile-nav-link').click(function(e) {
          e.preventDefault();
          mobileNav.addClass('active');
          mobileNav.find('a').each(function() {
            $(this).removeAttr('tabindex');
          });
          mobileNav.find(':input').each(function() {
            $(this).removeAttr('tabindex');
          });
          mobileNav.find('button').each(function() {
            $(this).removeAttr('tabindex');
          });
        });
        $('.mobile-nav .btn-close').click(function(e) {
          e.preventDefault();
          mobileNav.removeClass('active');
          mobileNav.find('a').each(function() {
            $(this).attr('tabindex', '-1');
          });
          mobileNav.find(':input').each(function() {
            $(this).attr('tabindex', '-1');
          });
          mobileNav.find('button').each(function() {
            $(this).attr('tabindex', '-1');
          });
        });

        $('.mobile-nav .has-subnav > a').click(function(e){
          e.preventDefault();
         // $('.mobile-nav .has-subnav').removeClass('active');
          $(this).parent().toggleClass('active');
        });

        $('.mobile-nav .has-subnav > a').each(function(){
          $(this).next().prepend('<li><a href="'+$(this).attr('href')+'">View '+$(this).text()+'</a><li>');
        });



        ////////////////
        // Alerts Bar //
        ////////////////

        $('.alert-bar__wrapper .btn-close').click(function(e){
          e.preventDefault();
          $('.alert-bar__wrapper').removeClass('active');
        });



        /////////////////////////////
        // Masthead Slick Carousel //
        /////////////////////////////

        $('.masthead__slides').slick({
          centerMode: true,
          centerPadding: '160px',
          slidesToShow: 1,
          infinite: true,
      pauseOnHover:true,
          prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '100px',
                slidesToShow: 1
              }
            },
            {
              breakpoint: 920,
              settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
              }
            }
          ]
        });


        //////////////////////////////////
        // Homepage Latest Posts Slider //
        //////////////////////////////////


        $('.homepage-news-listings__row').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          prevArrow: '<button class="homepage-news-listings__prev-button"><i class="icon-circle-arrow-left"></i></button>',
          nextArrow: '<button class="homepage-news-listings__next-button"><i class="icon-circle-arrow-right"></i></button>',
          appendArrows: $('.homepage-news-listings__nav-container'),
          responsive: [
            {
              breakpoint: 920,
              settings: {
                arrows: true,
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 1
              }
            }
          ]
        });



        ////////////////////////////////////
        // Homepage Featured Event Slider //
        ////////////////////////////////////


        $('.homepage-events__featured-events').slick({
          arrows: false,
          dots: true,
          dotsClass: 'homepage-events__featured-events__slider-dots'
        });

        $('.homepage-events__featured-events__slider-dots li button').text("");



        /////////////////////
        // STAFF DIRECTORY //
        /////////////////////

        $('.staff-listing').click(function(e){
          $('.staff-listing').removeClass('active');
          $(this).addClass('active');
        });

        $('.staff-listing .btn-close').click(function(e){
          e.preventDefault();
          e.stopPropagation();
          $(this).parent().parent().removeClass('active');
        });

        $('.toggle-departments').click(function(e){
          e.preventDefault();
          $(this).toggleClass('active');
          $('.staff-directory__department-filters').toggleClass('active');
        });

        // Alphabetical Filter
        $('.staff-directory__alphabet-filter').on('change',function(e){
          var target = e.target || e.srcElement;

          var checkboxValues = '';
          $('.staff-directory__alphabet-filter:checked').each(function(i,e){
            checkboxValues += $(e).val();
          });

          // check if no filters are active first so we show all
          if (checkboxValues === '') {
            $('.staff-listing').removeClass('alpha-hide');
            $('.alpha-divider').removeClass('alpha-hide');
          } else {
            $('.staff-listing').each(function(i,e){
              var alphaMatch = $(e).filter(function(i,e){
                var re = new RegExp('['+checkboxValues+']');
                return $(e).data('alpha').match(re);
              });

              if (!alphaMatch.length) {
                $(e).removeClass('active').addClass('alpha-hide');
              } else {
                $(e).removeClass('alpha-hide');
              }
            });

            $('.alpha-divider').each(function(i,e){
              var alphaMatch = $(e).filter(function(i,e){
                var re = new RegExp('['+checkboxValues+']');
                return $(e).data('alpha').match(re);
              });

              if (!alphaMatch.length) {
                $(e).removeClass('active').addClass('alpha-hide');
              } else {
                $(e).removeClass('alpha-hide');
              }
            });
          }

        });

        // Department Filter
        $('.staff-directory__department-filter').on('change',function(e){
          var target = e.target || e.srcElement;

          var checkboxValues = [];
          $('.staff-directory__department-filter:checked').each(function(i,e){
            checkboxValues.push($(e).val());
          });

          // check if no filters are active first so we show all
          if (checkboxValues.length === 0) {
            $('.staff-listing').removeClass('department-hide');
            $('.alpha-divider').removeClass('department-hide');
          } else {
            $('.alpha-divider').addClass('department-hide');
            $('.staff-listing').each(function(i,e){
              var departmentMatch = $(e).filter(function(i,e){
                var currentDataDepartmentAttr = $(e).data('department');
                return (checkboxValues.indexOf(currentDataDepartmentAttr) >= 0) ? true : false;
              });

              if (!departmentMatch.length) {
                $(e).removeClass('active').addClass('department-hide');
              } else {
                $(e).removeClass('department-hide');
              }
            });

            $('.staff-listing').promise().done(function(){
                $('.staff-listing').each(function(i,e){
                  if (!$(e).hasClass('department-hide') && !$(e).hasClass('alpha-hide')) {
                    $(e).prevAll('.alpha-divider:first').removeClass('alpha-hide department-hide');
                  }
                });
            });
          }

        });



        ////////////////////////////////
        // DOCUMENTS & DOWNLOADS TABS //
        ////////////////////////////////

        $('.document-listing').click(function(e){
          $('.document-listing').removeClass('active');
          $(this).addClass('active');
        });

        $('.document-listing .btn-close').click(function(e){
          e.preventDefault();
          e.stopPropagation();
          $(this).parent().parent().removeClass('active');
        });

        $('.toggle-document-types').click(function(e){
          e.preventDefault();
          $(this).toggleClass('active');
          $('.document-filters').toggleClass('active');
        });

        // Year Filter
        $('.documents-and-downloads__year-filter').on('change',function(e){
          var target = e.target || e.srcElement;

          var checkboxValues = [];
          $('.documents-and-downloads__year-filter:checked').each(function(i,e){
            checkboxValues.push(parseInt($(e).val(),10));
          });

          // check if no filters are active first so we show all
          if (checkboxValues.length === 0) {
            $('.document-listing').removeClass('year-hide');
            $('.year-divider').removeClass('year-hide');
          } else {
            $('.document-listing').each(function(i,e){
              var yearMatch = $(e).filter(function(i,e){
                var currentDataYearAttr = parseInt($(e).data('year'),10);
                return (checkboxValues.indexOf(currentDataYearAttr) >= 0) ? true : false;
              });

              if (!yearMatch.length) {
                $(e).removeClass('active').addClass('year-hide');
              } else {
                $(e).removeClass('year-hide');
              }
            });

            $('.year-divider').each(function(i,e){
              var yearMatch = $(e).filter(function(i,e){
                var currentDataYearAttr = parseInt($(e).data('year'),10);
                return (checkboxValues.indexOf(currentDataYearAttr) >= 0) ? true : false;
              });

              if (!yearMatch.length) {
                $(e).removeClass('active').addClass('year-hide');
              } else {
                $(e).removeClass('year-hide');
              }
            });
          }

        });

        // Document Type Filter
        $('.documents-and-downloads__document-filter').on('change',function(e){
          var target = e.target || e.srcElement;

          var checkboxValues = [];
          $('.documents-and-downloads__document-filter:checked').each(function(i,e){
            checkboxValues.push($(e).val());
          });

          // check if no filters are active first so we show all
          if (checkboxValues.length === 0) {
            $('.document-listing').removeClass('document-type-hide');
            $('.year-divider').removeClass('document-type-hide');
          } else {
            $('.year-divider').addClass('document-type-hide');
            $('.document-listing').each(function(i,e){
              var documentMatch = $(e).filter(function(i,e){
                var currentDataDocumentAttr = $(e).data('document');
                return (checkboxValues.indexOf(currentDataDocumentAttr) >= 0) ? true : false;
              });

              if (!documentMatch.length) {
                $(e).removeClass('active').addClass('document-type-hide');
              } else {
                $(e).removeClass('document-type-hide');
              }
            });

            $('.document-listing').promise().done(function(){
                $('.document-listing').each(function(i,e){
                  if (!$(e).hasClass('document-type-hide') && !$(e).hasClass('year-hide')) {
                    $(e).prevAll('.year-divider:first').removeClass('year-hide document-type-hide');
                  }
                });
            });
          }
        });


        //////////////////////////////////////////
        // FINANCIAL DOCUMENTS & DOWNLOADS TABS //
        //////////////////////////////////////////

        $('.document-listing').click(function(e){
          $('.document-listing').removeClass('active');
          $(this).addClass('active');
        });

        $('.document-listing .btn-close').click(function(e){
          e.preventDefault();
          e.stopPropagation();
          $(this).parent().parent().removeClass('active');
        });

        $('.toggle-financial-document-types').click(function(e){
          e.preventDefault();
          $(this).toggleClass('active');
          $('.financial-document-filters').toggleClass('active');
        });

        // Year Filter
        $('.financial-documents-and-downloads__year-filter').on('change',function(e){
          var target = e.target || e.srcElement;

          var checkboxValues = [];
          $('.financial-documents-and-downloads__year-filter:checked').each(function(i,e){
            checkboxValues.push(parseInt($(e).val(),10));
          });

          // check if no filters are active first so we show all
          if (checkboxValues.length === 0) {
            $('.document-listing').removeClass('year-hide');
            $('.year-divider').removeClass('year-hide');
          } else {
            $('.document-listing').each(function(i,e){
              var yearMatch = $(e).filter(function(i,e){
                var currentDataYearAttr = parseInt($(e).data('year'),10);
                return (checkboxValues.indexOf(currentDataYearAttr) >= 0) ? true : false;
              });

              if (!yearMatch.length) {
                $(e).removeClass('active').addClass('year-hide');
              } else {
                $(e).removeClass('year-hide');
              }
            });

            $('.year-divider').each(function(i,e){
              var yearMatch = $(e).filter(function(i,e){
                var currentDataYearAttr = parseInt($(e).data('year'),10);
                return (checkboxValues.indexOf(currentDataYearAttr) >= 0) ? true : false;
              });

              if (!yearMatch.length) {
                $(e).removeClass('active').addClass('year-hide');
              } else {
                $(e).removeClass('year-hide');
              }
            });
          }

        });

        // Document Type Filter
        $('.financial-documents-and-downloads__document-filter').on('change',function(e){
          var target = e.target || e.srcElement;

          var checkboxValues = [];
          $('.financial-documents-and-downloads__document-filter:checked').each(function(i,e){
            checkboxValues.push($(e).val());
          });

          // check if no filters are active first so we show all
          if (checkboxValues.length === 0) {
            $('.document-listing').removeClass('document-type-hide');
            $('.year-divider').removeClass('document-type-hide');
          } else {
            $('.year-divider').addClass('document-type-hide');
            $('.document-listing').each(function(i,e){
              var documentMatch = $(e).filter(function(i,e){
                var currentDataDocumentAttr = $(e).data('document');
                return (checkboxValues.indexOf(currentDataDocumentAttr) >= 0) ? true : false;
              });

              if (!documentMatch.length) {
                $(e).removeClass('active').addClass('document-type-hide');
              } else {
                $(e).removeClass('document-type-hide');
              }
            });

            $('.document-listing').promise().done(function(){
                $('.document-listing').each(function(i,e){
                  if (!$(e).hasClass('document-type-hide') && !$(e).hasClass('year-hide')) {
                    $(e).prevAll('.year-divider:first').removeClass('year-hide document-type-hide');
                  }
                });
            });
          }
        });



        /////////////////////////////////////
        // Secure Document Area Login AJAX //
        /////////////////////////////////////

        $('#form-id-submit-button').on('click',function(e){

          e.preventDefault();

          var formData = new FormData($('#form-id').get(0));

          // there should be a function in functions.php with this name
          formData.append("action", "secure_document_area_form_auth");

          // the nonce is sent here from lib/setup.php
          formData.append("security", secure_documents_form.nonce);

          $.ajax({

            url: secure_documents_form.ajaxUrl,
            type: 'POST',
            dataType: "json",
            data: formData,
            contentType: false, // workaround 1
            processData: false, // workaround 2
            cache: false, // no cache
            success: function(response) {
              // format response JSON according to JSend spec

              if (response.status === 'success') {

              }
              else if (response.status === 'fail') {
                var failMessage = '';
              }
              else if (response.status === 'error') {
                var errorMessage = response.message;
              }

            },
            error: function(response) {

              var responseMessage = "There was an error, please try again";

            }

          });

        });
    $('.homepage-eventsauto__featured-events').slick({
      autoplay:true,
      arrows: false,
      dots: true,
      dotsClass: 'homepage-eventsauto__featured-events__slider-dots'
    });


     // Master Head Autoplay


    $('.mastheadauto__slides').slick({
      autoplay:true,
      autoplaySpeed:10000,
      centerMode: true,
      centerPadding: '160px',
      slidesToShow: 1,
      infinite: true,
      pauseOnHover:true,
      prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
      responsive: [
      {
        breakpoint: 1200,
        settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '100px',
        slidesToShow: 1
        }
      },
      {
        breakpoint: 920,
        settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 1
        }
      }
      ]
    });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired



        //////////////////////////////////////////////////////////
        // Dropdown arrow for Google Translator in Utility Menu //
        //////////////////////////////////////////////////////////

        setTimeout(function(){$('.goog-te-combo').after('<i class="fa fa-phone" style="color:#FFF;"></i>');},500);



        /////////////////////////////////////////////////////////////////////////////
        // Make Search button in Primary Nav Right contain a magnifying glass icon //
        /////////////////////////////////////////////////////////////////////////////

        $('.primary-nav__container form.primary-nav__menu-item--search > .search-submit').after('<button class="search-submit" type="submit"><i class="fa fa-search"></i></button>');
        $('.primary-nav__container form.primary-nav__menu-item--search > .search-submit').first().remove();
        $('.mobile-nav form.primary-nav__menu-item--search > .search-submit').after('<button class="search-submit" type="submit" tabindex="-1"><i class="fa fa-search"></i></button>');
        $('.mobile-nav form.primary-nav__menu-item--search > .search-submit').first().remove();
        $('form.primary-nav__menu-item--search .search-field').attr('placeholder', "Search...");

      }

    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
